import React, {FunctionComponent, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Image, Row, Stack} from "react-bootstrap";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import * as OPA from "open-personal-archive-base";
import "../../App.scss";
import {logLogoutAttempt} from "../../Firebase";
import {EmptyProps} from "../../Interfaces";
import Logo from "../../assets/adcumulate_logo_48.png";

const HeaderView: FunctionComponent<EmptyProps> = (props: EmptyProps) => {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [showLogin, setShowLogin] = useState(OPA.isNullish(currentUser));

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setShowLogin(OPA.isNullish(user));
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    const authUser = OPA.convertNonNullish(auth.currentUser);
    logLogoutAttempt({firebaseUserId: authUser.uid, emailAddress: authUser.email});
    await auth.signOut();
  };

  let requestAccessJsx = (
    <Link to={"/requestaccess"}>
      <Button><b>Request Access</b></Button>
    </Link>
  );
  let authJsx = (
    <Link to={"/login"}>
      <Button><b>Login</b></Button>
    </Link>
  );
  if (!showLogin) {
    requestAccessJsx = <></>;
    authJsx = (
      <Button onClick={handleLogout}><b>Logout</b></Button>
    );
  }

  return (
    <Container className="Header GridNoPad" fluid>
      <Row className="GridNoPad">
        <Col className="GridNoPad" xs={3}>
          <Link to="/">
            <Image className="LogoContainer" src={Logo} alt="adcumulate™ logo" rounded />
          </Link>
        </Col>
        <Col className="GridNoPad" xs={6}>
          <h3 style={{paddingTop: 16}}>Welcome to the adcumulate™ Private Beta!</h3>
        </Col>
        <Col className="GridNoPad" xs={3}>
          <Stack className="HeaderContainerRight" direction="horizontal" gap={3}>
            {requestAccessJsx}
            {authJsx}
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default HeaderView;
