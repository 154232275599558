import {FunctionComponent} from "react";
import {RouteObject} from "react-router-dom";
import * as C from "./Constants";

export type EmptyProps = object;
export const urlBase = C.domainUrl;

export interface RoutableFunctionComponent<P = object> extends FunctionComponent<P> {
  placeholderText: string,
  routes: RouteObject,
  displayNameGenerator: (args: Record<string, string | undefined>) => string,
  linkToGenerator: (args: Record<string, string | undefined>) => string,
  urlGenerator: (args: Record<string, string | undefined>) => string,
}
