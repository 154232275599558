/* eslint-disable */
const PrivacyPolicy =
`adcumulate™ Privacy Policy
Effective as of publication to our site on May 11th, 2023

Please note, by using our website and/or services, you also confirm your agreement to our Terms of Use (see [TERMS_OF_USE]).


You, the "User", agree to all of the provisions below:

1. Introduction
This policy, the adcumulate™ "Privacy Policy", describes what information is collected by our websites, tools, applications and services (referred to collectively as our "Services") and how we use that information.

Your use of our Services confirms your agreement to our current "Privacy Policy", as well as the agreement of any business entity that you interact on behalf of.

We may at any time or our choosing update our "Privacy Policy" currently in effect by posting a new version of our "Privacy Policy" on our website. Your continued use of our Services confirms your agreement to such updates to our "Privacy Policy", as well as the agreement of any business entity that you interact on behalf of.

2. Information that We Collect about ALL Users

Currently, adcumulate™ provides a website (see [HOMEPAGE]) with a landing page that is publicly accessible to all users, whether they have registered for an account or not.

For all such users, adcumulate™ may record information such as IP Address, GPS location, which features are accessed, and other information useful for marketing, analytics, and/or other business purposes.

This information is kept private and only possibly shared outside adcumulate™ systems (which includes any cloud service providers who store and/or manage any of the adcumulate™ system data) in aggregate, such that no personally identifiable information is exposed.

3. Information that We Collect about Potential Users

Currently, the adcumulate™ website is in "Private Beta", so only pre-approved Users are allowed to register for accounts and access features provided by our systems.

To allow potential users to express their interest in using our website and systems, we provide an Access Request page (see [ACCESS_REQUEST]) where potential users can submit their 1) Name, 2) Phone Number, 3) Email Address, 4) Organization Name, 5) a URL providing more information about their Organization, and 6) a field where potential users can provide any additional information that they see fit for their access request.

The adcumulate™ systems record and store this information for the purpose of contacting the potential user in accordance with our Terms of Use (see [TERMS_OF_USE]).

This information is kept private within the adcumulate™ systems (which includes any cloud service providers who store and/or manage any of the adcumulate™ system data).

4. Information that We Collect about Registered Users

Once the adcumulate™ staff has determined that a potential user should be granted access to our Private Beta, we reach out to the potential user using the contact information provided with the information necessary to register for an adcumulate™ user account.

This information may include an email sent to the potential user's email address with an invitation to join our system, including a private link that allows that potential user to register for an adcumulate™ user account, though we may communicate this information through other means as our or the potential user's needs dictate.

Such information may contain tracking technology such that we can determine if the invitation email has been read or the link has been visited, though this is not guaranteed to be the case.

Once a potential user has acted on our invitation and registered for an account (including creating a username and password), we will prompt the newly registered user to confirm some or all of the information that they have already provided and may request further information about them and/or their Organization, as well.

The information we record about registered users will be kept private as much as possible, but may be used in accordance with our Terms of Use (see [TERMS_OF_USE]) to provide functionality inherent to our systems.

For example, if a registered user acts as a Buyer in our system and purchases an advertising Opportunity through our system, the Seller will necessarily learn the real identity of the Buyer both to confirm that the Buyer is acceptable to the Seller and, if accepted, for the purpose of coordinating execution of the contract for the advertising Opportunity.

Regardless of usage, the username for any registered user, as well as other information such as when that user joined, how active that user has been in Buying or Selling within our system, and what ratings and/or reviews that user has garnered, will be visible to other registered users (and possibly visible to unregistered users in the future).

5. Statement about the Future

As our system is currently in Private Beta, our system and business strategies are evolving, so we may need to change some or all of our Privacy Policy in the future.

In making such changes, we will strive to maintain the privacy of our users, while at the same time enhancing the viability of our business.

As mentioned above, we reserve the right to change this Privacy Policy as our needs dictate, and your continued usage of our system will confirm your agreement, as well as the agreement of any business entity that you interact on behalf of, to such changes.
`;
export default PrivacyPolicy;
