/* eslint-disable */
const TermsOfUse =
`adcumulate™ Terms of Use
Effective as of publication to our site on May 12th, 2023

You, the "User", agree to all of the provisions below:

1. Introduction
This policy, the adcumulate™ "Terms of Use", as well as any other terms, conditions, and/or policies posted on our website, govern the terms under which adcumulate™ provides access and use of our websites, tools, applications and services (referred to collectively as our "Services").

By using our Services, you are entering into a legally binding contract with adcumulate™, 6241 Freedom Lane, Citrus Heights, CA 95621, which shall be referred to as "adcumulate™", "adcumulate", "adcumulate.com", "us" or "we" within this agreement.

In the event that either side enters a claim or dispute against the other, these "Terms of Use" govern how such claims or disputes shall be resolved.

2. General Notice
Your use of our Services confirms your acceptance of this agreement, our current "Terms of Use".

We may at any time or our choosing update our "Terms of Use" currently in effect by posting a new version of our "Terms of Use" on our website. Your continued use of our Services confirms your acceptance of such updates to our "Terms of Use".

If any provision of this agreement is legally void, invalid, or unenforceable, such provision shall be struck out, but any other provisions shall remain valid and enforceable to the maximum extent allowed by the law.

By omitting reference to any specific matter, we do not waive any right or accept any limitation in regards to such matter.

As adcumulate™ is currently in "Private Beta" state and its services are evolving, we do NOT guarantee any specific level of service in regards to the availability or performance of our Services, as our Services may cease or transition in nature at any time. Accordingly, any Services that we provide may terminate and/or any user information that has been entered into our system may become inaccessible at any time in the future. Such termination or inaccessibility would NOT provide users with any cause for claim against us.

In the event that adcumulate™ transitions to a new business entity, adcumulate™ is absorbed into another business entity, or the properties of adcumulate™ change ownership in any manner, we may assign this agreement for any reason and at our will, without the need to obtain any new consent from you (the other party).

In the event that you use our Services on behalf of a business entity, you affirm and guarantee that you are authorized to such usage on behalf of that business entity, that that business entity agrees to these Terms of Use, and that any adcumulate™ user account that is used to access or utilize our Services on behalf of such business entity remains owned and controlled by that business entity.

By using our website and/or services, you also confirm your agreement, as well as the agreement of any business entity that you interact on behalf of, to our Privacy Policy (see [PRIVACY_POLICY]).

The name "adcumulate" and other adcumulate™ marks, logos, designs, and phrases that we use in connection with our Services are trademarks, service marks, or trade dress of adcumulate™ and may not be used without the express written consent of adcumulate™.

2. adcumulate™ Purpose
The purpose of adcumulate™ is to provide a platform for businesses to offer advertising opportunities on their premises to other businesses, and for other businesses to bid on or purchase such advertising opportunities from the businesses selling those advertising opportunities. In these Terms of Use, we shall refer to such an advertising opportunity as an "Opportunity", as such Opportunities are offered and sold through interactions between Buyers and Sellers using our Services.

In regards to any agreement for sale for one or more Opportunities or any other contract entered into by such businesses using our Services, adcumulate™ is NOT a party to such contract and may not be held liable for any deficiency of any party to such contract or any aspect of enforcement of such contract. Additionally, adcumulate™ is NOT responsible for any costs (examples include, but are not limited to, design fees, printing costs, and licensing royalties) related to the execution of such contracts.

Although adcumulate™ may offer to help facilitate interactions or provide advice on aspects of interactions between such businesses engaging in the process of forming and executing such contracts, it is your decision to use those facilitations and/or advice, or to refrain from doing so. Your decision to use any of our facilitations and/or advice, or not to do so, does NOT confer any liability upon us for the effects and/or outcomes related to your decision.

adcumulate™ does NOT warrant or guarantee any aspect of any Opportunity displayed (or otherwise marketed or provided) to you through any of its Services.

Additionally, adcumulate™ may at times display information that is intended to illustrate a constructive example of how to use our system. Such illustrative information may or may NOT be clearly labeled as such, depending on how adcumulate™ sees fit to present such information for its business purposes. Furthermore, adcumulate™ does NOT imply or warrant in any manner that such illustrative information actually relates in any way to any real advertising Opportunity and explicitly advises you NOT to factor such illustrative information into your decision making process when offering, bidding, or purchasing any advertising Opportunity.

3. adcumulate™ Usage
You agree to use adcumulate™'s Services in "good faith" under the Purpose for which they are provided, namely to buy and sell advertising Opportunities. Additionally, you agree NOT to use any aspect of any of our Services to compete with or reverse-engineer adcumulate™ for any reason.

If you are deemed to NOT be using adcumulate™'s Services in "good faith", your access to our Services may be altered, suspended, or terminated, as we see fit. In resolving such matters of misuse, we reserve the right to take any action available to us to restrict or prevent the usage or our Services in such regard. Any leniency shown to any specific User in any regard does NOT guarantee that such leniency will be afforded to any other User.

We reserve the right to refuse, preclude, modify, or terminate our Services for any potential or actual User at any time, as we see fit in regards to any specific User. 

Additionally, by using our Services, you stipulate that:
1) You are at least 18 years of age and capable of entering into legally binding contracts,
2) You will only post information that is to the best of your knowledge BOTH correct AND accurate, and that you accept full responsibility for the correctness and accuracy of such information,
3) You will NOT breach any government laws or regulations in your usage of our Services,
4) You will NOT breach or circumvent any aspect of these Terms of Use or any other policies under which we provide Services to you,
5) You will NOT fail to pay for Opportunities purchased by you, unless for good reason that is communicated to us,
6) You will NOT fail to provide Opportunities sold by you, unless for good reason that is communicated to us,
7) You will NOT interfere with any auction or listing of any Opportunity for a purpose that is NOT in "good faith", such as to artificially drive up the price of an Opportunity or to purchase an Opportunity for detrimental reasons,
8) You will NOT use our Services in a manner that attacks or otherwise weakens the performance of our systems,
9) You will NOT post information that is inappropriate, libelous, deceptive, false or misleading,
10) You will NOT infringe any Intellectual Property (IP) rights of adcumulate™, whether patent, trademark, copyright, or other,
11) You will NOT infringe any Intellectual Property (IP) rights of any other User whose information you gain access to through our Services,
12) You will NOT post malicious, abusive, otherwise detrimental content through our Services,
13) You will NOT use any information to which you gain access through our Services to post malicious, abusive, or otherwise detrimental content through our Services or elsewhere, and
14) You will not share your login credentials or transfer your account to another person without BOTH informing us AND gaining our consent prior to any such transfer.

4. Buying and Selling
When you sell an Opportunity using adcumulate™'s Services, you (the "Seller") take full responsibility for the correctness and accuracy of the information for the Opportunity that you are selling, regardless of whether you are selling the Opportunity by auction or by set price. Such information includes, but is not limited to, information about the location, placement, and reach of the Opportunity.

When you bid for or offer to purchase an Opportunity using adcumulate™'s Services, you (the "Buyer") take full responsibility for the correctness and accuracy of your bid or offer for purchase, as well as any information communicated to the Seller with your bid or offer for purchase. Additionally, you agree ONLY to place bids and offers for purchase in "good faith", and you acknowledge that such bids or offers for purchase may not be acceptable to the Seller, even if the monetary value meets or exceeds the Sellers stated or desired price for the Opportunity on which such bids or offers for purchase were placed.

Additionally, a Seller may include information about the type of Buyer that the Seller specifically seeks to purchase the Seller's Opportunity. This information is intended to ensure good "fit" between the Buyer and Seller and to aid in forming and maintaining a constructive business relationship between the Buyer and Seller.

To ensure that a Seller is NOT unfairly burdened by advertising for a Buyer that falls outside of their desired contingent of Buyers, upon a winning bid or offer for purchase being placed, the Seller shall have the right of final approval upon inspecting the identity of the Buyer before accepting that winning bid or offer for purchase. If the Seller rejects that winning bid or offer for purchase, that Seller must publish the Opportunity as a new auction or listing in order to find a new Buyer through our services.

Buyers agree NOT to circumvent this system and NOT to intentionally re-bid on an Opportunity for which they were previously rejected by the Seller during the final approval process.

Also, you agree NOT to attempt to move your interactions with any other users of our Services (such as other Buyers or Sellers) to means of communication (examples include, but are not limited to, email, phone, and/or social networking messaging) other than those directly provided to you by our Services, unless you are doing so for a specific purpose that our Services directly advise you specifically to undertake (if our Services advise such an action, you will receive an email, notification, phone call, or other means of communication from adcumulate™ clearly advising such an action, and such an action will clearly be labeled as an "offline action"). If our Services specifically advise you to undertake an "offline action", once you have fulfilled the specific purpose of that "offline action", you agree to resume interaction with other users of our Services ONLY through our Services, until such time that our Services directly advise you to undertake a specific subsequent "offline action" (which may or may not occur, depending on the nature of your usage).

Additionally, you agree NOT to attempt to buy or sell advertising Opportunities with other users of our Services through means other than publishing such advertising Opportunities as listings or auctions through our Services. You agree NOT to attempt to DIRECTLY buy or sell advertising Opportunities with other users of our Services. Generally, you agree NOT to circumvent our Services when buying from or selling to other users of our Services.

5. Your Content
You agree to ONLY upload content through our Services that you are legally entitled to upload and that is in compliance with these Terms of Use. By uploading such content, you warrant that you hold all necessary legal rights to do so, and you agree to indemnify adcumulate™ (and its officers, employees, representatives, and subsidiaries) in the event that any action detrimental to adcumulate™ occurs as any consequence or result of the information that you uploaded.

Additionally, for any content that you upload, you agree to provide adcumulate™ with a perpetual, worldwide, irrevocable, non-exclusive, royalty-free, assignable, transferable, and sub-licensable right to reproduce, display, or otherwise use any and all Intellectual Property contained in that content for any purpose related to providing our Services, promoting our Services, marketing our Services, or any other function that is any way related to our Services.

In relation to any content that you uploaded, in the event a claim or dispute arises to which adcumulate™ is a party, you agree to indemnify adcumulate™ (and its officers, employees, representatives, and subsidiaries) against any penalty or any other detrimental result incurred against adcumulate™ (or its officers, employees, representatives, and subsidiaries).

6. Contacting You
By registering for an adcumulate™ User account, you agree that adcumulate™ may contact you by phone, email, physical mail, or any other means that you provide to adcumulate™, whether directly or indirectly through other websites or systems, for any reason adcumulate™ deems necessary.

Examples of reasons to contact you include, but are not limited to: 1) verifying the identity of your business, 2) helping you to create a listing to sell an advertising Opportunity, 3) notifying you of advertising Opportunities on which you may wish to place a bid, 4) resolving a dispute, 5) troubleshooting or otherwise supporting adcumulate™ account issues, 6) enforcing these Terms of Use, 7) or any other reason connected to your usage of our Services.

Without further notice, adcumulate™ may monitor or record any audio or video interaction with you (or agents interacting on your behalf) for the purpose of verifying any information that was provided, quality control, training, or otherwise as necessary for adcumulate™'s own legal protection.

7. Sold Opportunities
Upon a winning bid or offer for purchase being entered by a prospective Buyer and that winning bid or offer for purchase being accepted by the Seller, the advertising Opportunity is deemed as "Sold" within our system, and so the Opportunity is deemed a "Sold Opportunity".

Sold Opportunities create a contract between the Buyer and Seller. It is the responsibility of the Buyer and Seller to execute such contracts. In regards to any and all matters related to such contracts, adcumulate™ is NOT a party to such contracts and is NOT responsible for ensuring they are executed faithfully. Furthermore, adcumulate™ is NOT liable for any issues that arise from any aspects of such contracts.

However, if a Buyer and Seller do not successfully execute a contract on a Sold Opportunity, adcumulate™ may become involved for the purpose of helping to find a satisfactory resolution. Both the Buyer and Seller agree to indemnify adcumulate™ (and its officers, employees, representatives, and subsidiaries) against any negative repercussion resulting in any way from any involvement in any regards to any such contracts.

Additionally, regardless of whether or not contract issues are resolved in a manner satisfactory to both sides, adcumulate™ reserves the right to modify, reduce, or terminate access to our Services for any party involved in such issues.

8. Disclaimer of Warranties
Our Services are provided "AS IS", without warranty of any kind (express or implied), including (but not limited to) the warranties of merchantability, fitness for a particular purpose, and non-infringement. In no event shall adcumulate™ be liable for any claim, damages, or other liability, whether in an action of contract, tort, or otherwise, arising from, out of, or in connection with our Services or the use or other dealings in our Services.

9. Limitation of Liability
To the maximum extent allowed by law, in no event shall adcumulate™ (or its officers, employees, representatives, and subsidiaries) be liable to you or any third party for any damages, losses, or other detrimental outcomes under any claim at law or in equity that arises from the usage of our Services. You agree that adcumulate™ (and its officers, employees, representatives, and subsidiaries) is expressly excluded from any responsibility in any regards for all such damages, losses, and other detrimental outcomes, regardless of the situation under which such claims arise.

10. Maximum Claim
As some jurisdictions preclude disclaimers of warranties and limitations of liability, regardless of the previous paragraphs, if adcumulate™ (or its officers, employees, representatives, and subsidiaries) is found to be liable to you or any third party for any claim, the amount of the judgment shall not exceed the lesser of the amount of fees that you paid to adcumulate™ in relation to the dispute for the prior twelve (12) months for use of our Services or $100. 

11. Release
In the event that a dispute arises between you (or your representatives) and one or more other users (or their representatives), you release adcumulate™ (and its officers, employees, representatives, and subsidiaries) from claims, demands, penalties, damages, costs, or any other kind of repercussion, known or unknown, of such disputes. You also waive any protections that may limit the coverage of this release.

12. Indemnity
You agree to hold adcumulate™ (and its officers, employees, representatives, and subsidiaries) harmless from any cost, penalty, damage, or other adverse or detrimental effect arising from any claim or demand made by any third party related to your improper use of our Services, your breach of these Terms of Use, or your breach of any law or rights of a third party.

13. Application of Law
You agree that the laws of the State of California (unless pre-empted by U.S. Federal Law) shall govern any disputes that arise between you and adcumulate™, unless otherwise stated in these Terms of Use.

14. Agreement to Arbitration
For any claims or disputes that arise through your usage of our Services, both you and adcumulate™ agree to use binding and final arbitration, rather than pursue legal action in court or any other action in any other venue other than arbitration.

Additionally, both you and adcumulate™ agree that all such claims or disputes shall be brought forward on an individual basis, and neither side shall seek to consolidate similar claimants as a group or class. To this end, the arbitrator shall be powerless to resolve disputes brought forward as a group or class, and any relief decided by the arbitrator for a specific dispute shall only apply to the parties of that specific dispute.

Also, if you pursue claims or disputes by means of resolution other than arbitration, to the maximum extent allowed by law, by using our Services, you agree to indemnify adcumulate™ (and its officers, employees, representatives, and subsidiaries) fully against any action brought by you or your representatives seeking a decision against adcumulate™ (or its officers, employees, representatives, and subsidiaries) in any court or any other venue other than arbitration.
`;
export default TermsOfUse;
