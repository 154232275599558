import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Col, Container, Form, InputGroup, Row, Stack} from "react-bootstrap";
import {getApps} from "firebase/app";
import {HttpsCallableResult, getFunctions, httpsCallable} from "firebase/functions";
import * as OPA from "open-personal-archive-base";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";

const RequestAccessView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("RequestAccessView");
  });

  // const params = useParams();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationWebListingUrl, setOrganizationWebListingUrl] = useState("");
  const [otherInformation, setOtherInformation] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault(); // NOTE: Prevent form submission because we use a Firebase callable function to submit data

    const form = e.currentTarget;
    const isValid = form.checkValidity();
    setValidated(true);

    if (!isValid) {
      e.stopPropagation();
      return;
    }
    setButtonDisabled(true);

    const app = getApps()[0];
    const funcs = getFunctions(app);
    const funcName = "recordAccessRequest";
    const call = httpsCallable(funcs, funcName);
    const args = {contactName, contactPhone, contactEmail, organizationName, organizationWebListingUrl, otherInformation};

    call(args)
      .then((result: HttpsCallableResult<unknown>) => {
        const resultTyped = (result.data as OPA.ICallResult<OPA.IDocument>);
        handleResult(resultTyped);
      })
      .catch((result: HttpsCallableResult<unknown>) => {
        const resultTyped = (result.data as OPA.ICallResult<OPA.IDocument>);
        handleResult(resultTyped);
      });
  };
  const handleResult = (result: OPA.ICallResult<OPA.IDocument>): void => {
    console.log("CALL RESULT: " + result.success);
    console.log(result.message);

    if (result.success) {
      alert("Thank you for submitting your contact info. We will respond to you soon.");
      navigate("/");
    } else {
      alert("There was an error processing your information. Please make sure you submitted a valid phone number, email address, and web listing URL...");
      setButtonDisabled(false);
    }
  };

  return (
    <Form className="Content" noValidate validated={validated} onSubmit={handleSubmit}>
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={1}></Col>
          <Col className="GridNoPad" xs={10}>
            <Stack direction="vertical" gap={3}>
              <h2>Access Request Form</h2>
              <Form.Group id="contactNameValidation">
                <InputGroup>
                  <InputGroup.Text id="contactNameAddon">Contact Name:</InputGroup.Text>
                  <Form.Control id="contactName" type="text" required placeholder="Enter your name" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must provide your name.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="contactPhoneValidation">
                <InputGroup>
                  <InputGroup.Text id="contactPhoneAddon">Contact Phone:</InputGroup.Text>
                  <Form.Control id="contactPhone" type="text" required placeholder="Enter your business phone number" value={contactPhone} onChange={(e) => setContactPhone(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must provide your business phone number.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="contactEmailValidation">
                <InputGroup>
                  <InputGroup.Text id="contactEmailAddon">Contact Email:</InputGroup.Text>
                  <Form.Control id="contactEmail" type="email" required placeholder="Enter your business email address" value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must provide your business email address.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="organizationNameValidation">
                <InputGroup>
                  <InputGroup.Text id="organizationNameAddon">Organization Name:</InputGroup.Text>
                  <Form.Control id="organizationName" type="text" required placeholder="Enter the name of the organization that you work for" value={organizationName} onChange={(e) => setOrganizationName(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must provide the name of the organization that you work for.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="organizationWebListingValidation">
                <InputGroup>
                  <InputGroup.Text id="organizationWebListingAddon">Organization Web Listing:</InputGroup.Text>
                  <Form.Control id="organizationWebListingName" type="text" required placeholder="Enter the URL of the page for your organization on Yelp, Google Maps, or other listing provider" value={organizationWebListingUrl} onChange={(e) => setOrganizationWebListingUrl(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must provide a link to a web listing that refers to the organization that you work for.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="otherInformationValidation">
                <InputGroup>
                  <InputGroup.Text id="otherInformationAddon">Other Information (optional):</InputGroup.Text>
                  <Form.Control id="otherInformationName" type="text" as="textarea" rows={5} placeholder={"\n\n" + "Any other information that you wish to share with us"} value={otherInformation} onChange={(e) => setOtherInformation(e.target.value)} />
                </InputGroup>
              </Form.Group>
              <Button style={{width: 100}} type="submit" variant={(!buttonDisabled) ? "primary" : "secondary"} disabled={buttonDisabled}>Submit</Button>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={1}></Col>
        </Row>
      </Container>
    </Form>
  );
};

RequestAccessView.placeholderText = "[ACCESS_REQUEST]";
RequestAccessView.routes = {
  path: "requestaccess",
  element: <RequestAccessView />,
  children: [
    {path: "*", element: <div />},
  ],
};
RequestAccessView.displayNameGenerator = (args) => "Access Request Form";
RequestAccessView.linkToGenerator = (args) => RequestAccessView.routes.path!;
RequestAccessView.urlGenerator = (args) => new URL(RequestAccessView.linkToGenerator(args), urlBase).href;

export default RequestAccessView;
