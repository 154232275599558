import "./App.css";
import "./App.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {useRoutes} from "react-router-dom";
import {PageViewRoutes} from "./PageViewDirectory";
import HeaderView from "./components/common/HeaderView";
import FooterView from "./components/common/FooterView";

function App() {
  const routing = useRoutes(PageViewRoutes);

  return (
    <div className="main RootContainer">
      <div className="row-header">
        <HeaderView />
      </div>
      <div className="row-content">
        {routing}
      </div>
      <div className="row-footer">
        <FooterView />
      </div>
    </div>
  );
}

export default App;
