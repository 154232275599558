import React, {useEffect} from "react";
import {Link, Navigate, Outlet, useParams} from "react-router-dom";
import {Alert, Button, Image, Stack} from "react-bootstrap";
import * as OPA from "open-personal-archive-base";
import * as C from "../Constants";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";
import SearchSettingsBar from "./common/SearchSettingsBar";
import {auctionMocks, getPatronEstimateTypeText, getSponsorshipTypeText, getSponsorshipLocationTypeText, getBiddingStatusText, getAuctionStatusClassesAndText} from "../DataMock";

const AuctionView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  const params = useParams();
  useEffect(() => {
    logPageView("AuctionView", {auctionId: params.id});
  });

  const auctionMock = auctionMocks.find((a) => (a.id == params.id));
  OPA.assertNonNullish(auctionMock, "The auctionId must correspond to valid auction.");
  const auctionMockNonNull = OPA.convertNonNullish(auctionMock);

  let illustrativeOverlayJsx = <></>;
  if (auctionMockNonNull.isIllustrative) {
    illustrativeOverlayJsx = (
      <div className="SearchItemImageOverlay">Illustrative</div>
    );
  }

  let otherInfoJsx = <></>;
  if (!OPA.isNullishOrWhitespace(auctionMockNonNull.otherInformation)) {
    otherInfoJsx = (
      <span>
        <b>Other Info:</b> {auctionMockNonNull.otherInformation}
      </span>
    );
  }

  const auctionStatus = getAuctionStatusClassesAndText(auctionMockNonNull);
  const auctionIsOpen = auctionStatus[0];
  let auctionStatusJsx = <></>;
  if (auctionIsOpen) {
    auctionStatusJsx = (
      <Button>
        <b>Place Bid</b>
      </Button>
    );
  } else {
    auctionStatusJsx = (
      <Alert key="secondary" variant="secondary">
        <b>{auctionStatus[2]}</b>
      </Alert>
    );
  }

  let outrightBuyJsx = <></>;
  if (auctionIsOpen && auctionMockNonNull.allowOutrightBuy) {
    outrightBuyJsx = (
      <span>
        <b>Outright-Buy Price (price to end bidding): </b> {"$" + OPA.convertNonNullish(auctionMockNonNull.outrightBuyPrice)}
        {C.spacerText}
      </span>
    );
  }

  return (
    <div className="AuctionContainer">
      <Stack direction="horizontal" gap={4}>
        <Stack direction="vertical" gap={3}>
          <div className="SearchItemImageContainer">
            <Image className="AuctionImageContainer" src={auctionMockNonNull.image} alt="Auction Image" rounded fluid />
            {illustrativeOverlayJsx}
          </div>
          <Link to="/">Back to Search</Link>
        </Stack>
        <Stack direction="vertical" gap={3}>
          <h2>{auctionMockNonNull.sponsorshipTitle}</h2>
          <hr />
          <div>
            <b>Zipcode of Establishment:</b> {auctionMockNonNull.zipcode}
            {C.spacerText}
            <b>Patron Esitmate:</b> {auctionMockNonNull.patronEstimate + " " + getPatronEstimateTypeText(auctionMockNonNull.patronEstimateType, auctionMockNonNull.patronEstimateTypeOther)}
          </div>
          <div>
            <b>Term of Sponsorship:</b> {auctionMockNonNull.termOfSponsorshipInMonths} months
            {C.spacerText}
            <b>Sponsorship Type:</b> {getSponsorshipTypeText(auctionMockNonNull.sponsorshipType, auctionMockNonNull.sponsorshipTypeOther)}
            {C.spacerText}
            <b>Sponsorship Placement:</b> {getSponsorshipLocationTypeText(auctionMockNonNull.sponsorshipLocationType, auctionMockNonNull.sponsorshipLocationTypeOther)}
          </div>
          <div>
            <b>Additional Description:</b> {auctionMockNonNull.additionalSponsorshipDescription}
          </div>
          <div>
            <b>Desired Sponsors:</b> {auctionMockNonNull.desiredSponsorDescription}
          </div>
          {otherInfoJsx}
          <div>
            <div>
              {outrightBuyJsx}
              <b>Bidding Status:</b> {getBiddingStatusText(auctionMockNonNull)}
            </div>
          </div>
          {auctionStatusJsx}
        </Stack>
      </Stack>
    </div>
  );
};

AuctionView.placeholderText = "[AUCTION]";
AuctionView.routes = {
  path: "auctions",
  element:
    <div>
      <SearchSettingsBar />
      <Outlet />
    </div>,
  children: [
    {path: "*", element: <Navigate to="/404" />},
    {path: ":id", element: <AuctionView />},
  ],
};
AuctionView.displayNameGenerator = (args) => ("Auction: " + args["auctionName"]);
AuctionView.linkToGenerator = (args) => (AuctionView.routes.path! + "/" + args["auctionId"]);
AuctionView.urlGenerator = (args) => new URL(AuctionView.linkToGenerator(args), urlBase).href;

export default AuctionView;
