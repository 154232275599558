import React, {useEffect, useState} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import {Alert, Button, Col, Container, Form, InputGroup, Row, Stack} from "react-bootstrap";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import * as OPA from "open-personal-archive-base";
import {logLoginAttempt, logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";

const LoginView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("LoginView");
  });

  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault(); // NOTE: Prevent form submission because we use Firebase Auth to submit data

    const form = e.currentTarget;
    const isValid = form.checkValidity();
    setValidated(true);
    setErrorMessage("");

    if (!isValid) {
      e.stopPropagation();
      return;
    }
    setButtonDisabled(true);

    logLoginAttempt({emailAddress});
    const auth = getAuth();
    signInWithEmailAndPassword(auth, emailAddress, password)
      .then((userCredential) => {
        // const user = userCredential.user;
        navigate("/");
      })
      .catch((error) => {
        let message = (!OPA.isNullishOrWhitespace(error.message)) ? (error.message as string) : "Error occurred!";
        message = message.replace("Firebase: ", "");
        message = message.replace("Firebase:", "");
        message = message.replace("Firebase", "");
        setErrorMessage(message);
        setButtonDisabled(false);
      });
  };

  let errorMessageJsx = <></>;
  if (!OPA.isNullishOrWhitespace(errorMessage)) {
    errorMessageJsx = (
      <Alert key={"danger"} variant={"danger"}>
        {errorMessage}
      </Alert>
    );
  }

  return (
    <Form className="Content" noValidate validated={validated} onSubmit={handleSubmit}>
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={3}></Col>
          <Col className="GridNoPad" xs={6}>
            <Stack direction="vertical" gap={3}>
              <h2>Login</h2>
              <Form.Group id="emailAddressValidation">
                <InputGroup>
                  <InputGroup.Text id="emailAddressAddon">Email Address:</InputGroup.Text>
                  <Form.Control id="emailAddress" type="email" required placeholder="Enter the email address associated with your account" value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must enter your email address.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group id="passwordValidation">
                <InputGroup>
                  <InputGroup.Text id="passwordAddon">Password:</InputGroup.Text>
                  <Form.Control id="password" type="password" required placeholder="Enter the password for your account" value={password} onChange={(e) => setPassword(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    You must enter your password.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {errorMessageJsx}
              <Button style={{width: 100}} type="submit" variant={(!buttonDisabled) ? "primary" : "secondary"} disabled={buttonDisabled}>Submit</Button>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={3}></Col>
        </Row>
      </Container>
    </Form>
  );
};

LoginView.placeholderText = "[LOGIN]";
LoginView.routes = {
  path: "login",
  element: <LoginView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
  ],
};
LoginView.displayNameGenerator = (args) => "Login";
LoginView.linkToGenerator = (args) => LoginView.routes.path!;
LoginView.urlGenerator = (args) => new URL(LoginView.linkToGenerator(args), urlBase).href;

export default LoginView;
