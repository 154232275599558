import React, {useEffect} from "react";
import {Link, Navigate, Outlet} from "react-router-dom";
import {Alert, Col, Container, Row, Stack} from "react-bootstrap";
import {auctionMocks} from "../DataMock";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";
import SearchSettingsBar from "./common/SearchSettingsBar";
import SearchItemView from "./common/SearchItemView";

const SearchView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("SearchView");
  });

  // const params = useParams();
  const searchItemList = auctionMocks.map((a) => <SearchItemView key={a.id} auctionId={a.id}></SearchItemView>);

  return (
    <div className="Content">
      <Container className="GridNoPad" fluid>
        <Row>
          <Col className="GridNoPad" xs={1}></Col>
          <Col className="GridNoPad" xs={10}>
            <Alert key="warning" variant="warning">
              NOTE: Only users who have logged-in with an <Link to={"/aboutus"}><b>adcumulate</b></Link>™
              {" "}user account will be able to view active auctions. If you wish to view auctions
              that are currently active, please <Link to={"/requestaccess"}><b>click here</b></Link>
              {" "}to begin the process of registering for a user account.
            </Alert>
          </Col>
          <Col className="GridNoPad" xs={1}></Col>
        </Row>
        <Row>
          <Col className="GridNoPad" xs={2}></Col>
          <Col className="GridNoPad" xs={8}>
            <Stack className="" direction="vertical" gap={3}>
              {searchItemList}
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

SearchView.placeholderText = "[HOMEPAGE]";
SearchView.routes = {
  path: "/",
  element:
    <div>
      <SearchSettingsBar />
      <Outlet />
    </div>,
  children: [
    {path: "*", element: <Navigate to="/404" />},
    {path: "/", element: <SearchView />},
    {path: "404", element: <div />},
    {path: "auctions", element: <Navigate to="/auctions" />},
  ],
};
SearchView.displayNameGenerator = (args) => "Home";
SearchView.linkToGenerator = (args) => SearchView.routes.path!;
SearchView.urlGenerator = (args) => new URL(SearchView.linkToGenerator(args), urlBase).href;

export default SearchView;
