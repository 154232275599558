import React, {useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";

const NotFoundView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("NotFoundView");
  });

  return (
    <div className="Content">
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={3}></Col>
          <Col className="GridNoPad" xs={6}>
            <Stack direction="vertical" gap={3}>
              <h2>Page Not Found</h2>
              <div>
                <p>
                  Please visit our <Link to={"/"}><b>Homepage</b></Link> to navigate to our
                  current set of webpages or visit our <Link to={"/aboutus"}><b>About Us</b></Link>
                  {" "}page to see our contact info...
                </p>
              </div>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={3}></Col>
        </Row>
      </Container>
    </div>
  );
};

NotFoundView.placeholderText = "[404_NOT_FOUND]";
NotFoundView.routes = {
  path: "404",
  element: <NotFoundView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
  ],
};
NotFoundView.displayNameGenerator = (args) => "Page Not Found";
NotFoundView.linkToGenerator = (args) => NotFoundView.routes.path!;
NotFoundView.urlGenerator = (args) => new URL(NotFoundView.linkToGenerator(args), urlBase).href;

export default NotFoundView;
