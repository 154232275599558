import React, {FunctionComponent} from "react";
import {Button, Col, Container, Form, InputGroup, Row, Stack} from "react-bootstrap";
import {EmptyProps} from "../../Interfaces";

const SearchSettingsBar: FunctionComponent<EmptyProps> = (props: EmptyProps) => {
  return (
    <div style={{display: "flex", justifyContent: "center"}}>
      <Stack className="Toolbar">
        <Container className="GridSmallPad" fluid>
          <Row className="GridNoPad">
            <Col className="GridNoPad" xs={4}>
              <InputGroup style={{marginLeft: 8}}>
                <InputGroup.Text id="locationAddon">Location:</InputGroup.Text>
                <Form.Control id="location" type="text" placeholder="Enter U.S. zipcode or GPS coordinates" />
              </InputGroup>
            </Col>
            <Col className="GridNoPad" xs={6}>
              <InputGroup style={{marginLeft: 20}}>
                <InputGroup.Text id="searchTermsAddon">Search Terms:</InputGroup.Text>
                <Form.Control id="searchTerms" type="text" placeholder="Enter keywords to search for" />
              </InputGroup>
            </Col>
            <Col className="GridNoPad" xs={1}>
              <div style={{marginLeft: 30, marginRight: 10}}>
                <Button variant="dark">Go</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Stack>
    </div>
  );
};

export default SearchSettingsBar;
