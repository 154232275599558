import {getApps} from "firebase/app";
import {HttpsCallableResult, getFunctions, httpsCallable} from "firebase/functions";
// import * as OPA from "open-personal-archive-base";

export type LogRecordType = "client_initialization" | "client_login" | "client_pageview" | "client_logout" | "function_call" | "storage_call";
export type LogRecordCallState = "occurred" | "started" | "succeeded" | "failed" | "canceled" | "interrupted";

export const logInitialization = (viewArgs?: any) => {
  logEvent("client_initialization", "occurred", "Application", viewArgs);
};

export const logPageView = (viewName: string, viewArgs?: any) => {
  logEvent("client_pageview", "occurred", viewName, viewArgs);
};

export const logLoginAttempt = (viewArgs?: any) => {
  logEvent("client_login", "started", "Authentication", viewArgs);
};

export const logLogoutAttempt = (viewArgs?: any) => {
  logEvent("client_logout", "started", "Authentication", viewArgs);
};

export const logEvent = (logRecordType: LogRecordType, logRecordCallState: LogRecordCallState, resourceName: string, resourceArgs?: any) => {
  const app = getApps()[0];
  const funcs = getFunctions(app);
  const funcName = "createLogRecord";
  const call = httpsCallable(funcs, funcName);
  const args = {logRecordType, logRecordCallState, resourceName, resourceArgs};

  call(args)
    .then((result: HttpsCallableResult<unknown>) => {
      console.log("LOGGING: " + funcName + " succeeded");
    })
    .catch((result: HttpsCallableResult<unknown>) => {
      console.log("LOGGING: " + funcName + " failed");
    });
};
