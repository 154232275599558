import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";
import * as TextFormatter from "../TextFormatter";
import termsOfUse from "../legal/adcumulate_TermsOfUse_2023_05_12";

const TermsOfUseView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("TermsOfUseView");
  });

  // NOTE: Rather than wait to open a text file, just import a constant containing the multi-line text
  // const [termsOfUse, setSetTermsOfUse] = useState("");
  // useEffect(() => {
  //   fetch(TermsOfUse)
  //     .then(async (file) => {
  //       const fileText = await file.text();
  //       setSetTermsOfUse(fileText);
  //     });
  // });

  const fileTextParts = termsOfUse.split("\n");
  let fileJsx = <></>;

  for (let i = 0; i < fileTextParts.length; i++) {
    const fileTextPart = fileTextParts[i];
    const fileTextPartJsx = TextFormatter.formatTextPartAsJsx(fileTextPart, i);

    fileJsx = <>{fileJsx}{fileTextPartJsx}</>;
  }

  return (
    <div className="Content">
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={2}></Col>
          <Col className="GridNoPad" xs={8}>
            <Stack direction="vertical" gap={3}>
              <h2>Terms of Use</h2>
              <div style={{textAlign: "left"}}>
                {fileJsx}
              </div>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

TermsOfUseView.placeholderText = "[TERMS_OF_USE]";
TermsOfUseView.routes = {
  path: "termsofuse",
  element: <TermsOfUseView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
  ],
};
TermsOfUseView.displayNameGenerator = (args) => "Terms of Use";
TermsOfUseView.linkToGenerator = (args) => TermsOfUseView.routes.path!;
TermsOfUseView.urlGenerator = (args) => new URL(TermsOfUseView.linkToGenerator(args), urlBase).href;

export default TermsOfUseView;
