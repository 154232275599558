import * as OPA from "open-personal-archive-base";
import {RoutableFunctionComponent} from "./Interfaces";
import NotFoundView from "./components/NotFoundView";
import AboutUsView from "./components/AboutUsView";
import SearchView from "./components/SearchView";
import AuctionView from "./components/AuctionView";
import RequestAccessView from "./components/RequestAccessView";
import LoginView from "./components/LoginView";
import NewUserView from "./components/NewUserView";
import PrivacyPolicyView from "./components/PrivacyPolicyView";
import TermsOfUseView from "./components/TermsOfUseView";

const PageViewDirectory = {
  NotFoundView: NotFoundView,
  SearchView: SearchView,
  TermsOfUseView: TermsOfUseView,
  PrivacyPolicyView: PrivacyPolicyView,
  AboutUsView: AboutUsView,
  RequestAccessView: RequestAccessView,
  LoginView: LoginView,
  NewUserView: NewUserView,
  AuctionView: AuctionView,
};
export default PageViewDirectory;

export const PageViews = OPA.getCollectionFromObject<RoutableFunctionComponent<object>>(PageViewDirectory, (pageView) => (pageView.routes !== undefined));
export const PageViewRoutes = PageViews.map((pv) => pv.routes);
