import * as firestore from "firebase/firestore";
import * as OPA from "open-personal-archive-base";
import DISC1 from "./assets/DISC1.png";
import MIC1 from "./assets/MIC1.png";
import SINK1 from "./assets/SINK1.png";
import TV1 from "./assets/TV1.png";
import TV2 from "./assets/TV2.png";
import TV3 from "./assets/TV3.png";
import TV4 from "./assets/TV4.png";

export type PatronEstimateType = "per_month" | "per_week" | "per_day" | "per_event" | "other";
export type SponsorshipType = "sign" | "adread" | "discount" | "other";
export type SponsorshipLocationType = "diningroom" | "bar" | "viewingarea" | "bathroom" | "other";
export type DateShim = Date;
export const now = (): DateShim => firestore.Timestamp.now().toDate();

export function getPatronEstimateTypeText(type: PatronEstimateType, other: string | null): string {
  if (type == "per_month") {
    return "per month";
  } else if (type == "per_week") {
    return "per week";
  } else if (type == "per_day") {
    return "per day";
  } else if (type == "per_event") {
    return "per event";
  } else {
    return OPA.convertNonNullish(other);
  }
}

export function getSponsorshipTypeText(type: SponsorshipType, other: string | null): string {
  if (type == "sign") {
    return "sign";
  } else if (type == "adread") {
    return "ad read";
  } else if (type == "discount") {
    return "discount";
  } else {
    return OPA.convertNonNullish(other);
  }
}

export function getSponsorshipLocationTypeText(type: SponsorshipLocationType, other: string | null): string {
  if (type == "diningroom") {
    return "dining room";
  } else if (type == "bar") {
    return "bar";
  } else if (type == "viewingarea") {
    return "viewing area";
  } else if (type == "bathroom") {
    return "bathroom";
  } else {
    return OPA.convertNonNullish(other);
  }
}

export function getBiddingStatusText(mock: IAuctionMock, currentDate?: Date | null): string {
  const isOpen = getAuctionStatusClassesAndText(mock, currentDate)[0];
  const enforceReservePrice = (!OPA.isNullish(mock.enforceReservePrice)) ? mock.enforceReservePrice : false;
  const allowOutrightBuy = (!OPA.isNullish(mock.allowOutrightBuy)) ? mock.allowOutrightBuy : false;

  if (isOpen) {
    return "Bidding is ongoing.";
  }
  if (OPA.isNullish(mock.largestBid)) {
    return "Bidding ended without a winning bid.";
  }

  const largestBid = OPA.convertNonNullish(mock.largestBid);
  const largestBidAmount = largestBid.bidAmount;

  if (allowOutrightBuy) {
    const outrightBuyPrice = OPA.convertNonNullish(mock.outrightBuyPrice);

    if (largestBidAmount >= outrightBuyPrice) {
      return "Purchased via outright-buy price.";
    }
  }

  if (enforceReservePrice) {
    const reservePrice = OPA.convertNonNullish(mock.reservePrice);

    if (largestBidAmount >= reservePrice) {
      return "Purchased via winning bid.";
    }
  }

  return "Bid amounts did not meet reserve price.";
}

export function getAuctionStatusClassesAndText(mock: IAuctionMock, currentDate?: Date | null): [boolean, string, string] {
  const date = (!OPA.isNullish(currentDate)) ? OPA.convertNonNullish(currentDate) : now();
  let isOpen = (date <= mock.auctionEndDate);

  if (mock.allowOutrightBuy && !OPA.isNullish(mock.largestBid)) {
    const largestBid = OPA.convertNonNullish(mock.largestBid);
    const largestBidAmount = largestBid.bidAmount;
    const outrightBuyPrice = OPA.convertNonNullish(mock.outrightBuyPrice);

    if (largestBidAmount >= outrightBuyPrice) {
      isOpen = false; // NOTE: The auction closes when the outright-buy price is met (or exceeded)
    }
  }

  const classes = (isOpen) ? "bg-info text-dark" : "bg-secondary text-light";
  const timeSinceEpochInMs = (mock.auctionEndDate.getTime() - date.getTime());
  const timeSinceEpochInDays = Math.floor(timeSinceEpochInMs / (1000 * 60 * 60 * 24));
  const status = (isOpen) ? (timeSinceEpochInDays + " days remaining") : "Auction Closed";
  return [isOpen, classes, status];
}

export interface IAuctionMock {
  id: string;
  isIllustrative: boolean;
  image: any;
  imageRelativePath: string;
  zipcode: string;
  patronEstimateType: PatronEstimateType;
  patronEstimateTypeOther: string | null;
  patronEstimate: number;
  sponsorshipTitle: string;
  sponsorshipType: SponsorshipType;
  sponsorshipTypeOther: string | null;
  sponsorshipLocationType: SponsorshipLocationType;
  sponsorshipLocationTypeOther: string | null;
  termOfSponsorshipInMonths: number;
  additionalSponsorshipDescription: string;
  desiredSponsorDescription: string;
  otherInformation: string;
  auctionEndDate: DateShim;
  showInAuctionListings: boolean;
  useAnonymousAuction: boolean;
  requireVerifiedBidders: boolean;
  enforceReservePrice: boolean;
  reservePrice: number | null;
  allowOutrightBuy: boolean;
  outrightBuyPrice: number | null;
  dateOfCreation: DateShim;
  hasBeenPublished: boolean;
  dateOfPublication: DateShim;
  largestBid: IAuctionBidMock | null;
}

export interface IAuctionBidMock {
  id: string;
  auctionId: string;
  bidAmount: number;
  otherInfo: string;
  dateOfCreation: DateShim;
}

export const auction1Mock: IAuctionMock = {
  id: "a0000000000000000001",
  isIllustrative: true,
  image: TV4,
  imageRelativePath: "/auctions/a0000000000000000001/images/a0000000000000000001_img01.png",
  zipcode: "95620",
  patronEstimateType: "per_week",
  patronEstimateTypeOther: null,
  patronEstimate: 100,
  sponsorshipTitle: "Restaurant seeks sponsor for 2 TV screens",
  sponsorshipType: "sign",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "diningroom",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 1, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We are flexible on the exact size and location of your sign.",
  desiredSponsorDescription: "We seek local Sacramento-area plumbing companies to bid.",
  otherInformation: "We are seeking a sponsor for 2 out of our 10 total TV screens.",
  auctionEndDate: new Date(2023, 3, 15),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 100,
  allowOutrightBuy: true,
  outrightBuyPrice: 300,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000001",
    auctionId: "a0000000000000000001",
    bidAmount: 200,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 7),
  },
};

export const auction2Mock: IAuctionMock = {
  id: "a0000000000000000002",
  isIllustrative: true,
  image: MIC1,
  imageRelativePath: "/auctions/a0000000000000000002/images/a0000000000000000002_img01.png",
  zipcode: "95610",
  patronEstimateType: "per_event",
  patronEstimateTypeOther: null,
  patronEstimate: 200,
  sponsorshipTitle: "Local Bar seeks sponsor for live events",
  sponsorshipType: "adread",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "bar",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 2, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will give your business a 15 second shout-out during our live events.",
  desiredSponsorDescription: "We seek local home appliance stores to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 17),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 500,
  allowOutrightBuy: true,
  outrightBuyPrice: 800,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000002",
    auctionId: "a0000000000000000002",
    bidAmount: 800,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 5),
  },
};

export const auction3Mock: IAuctionMock = {
  id: "a0000000000000000003",
  isIllustrative: true,
  image: TV2,
  imageRelativePath: "/auctions/a0000000000000000003/images/a0000000000000000003_img01.png",
  zipcode: "95630",
  patronEstimateType: "per_day",
  patronEstimateTypeOther: null,
  patronEstimate: 50,
  sponsorshipTitle: "Sports Bar seeks sponsor for main projection screen",
  sponsorshipType: "sign",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "viewingarea",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 6, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will prominently display your business name under our gigantic projection screen.",
  desiredSponsorDescription: "We seek local home service providers to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 19),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 1000,
  allowOutrightBuy: true,
  outrightBuyPrice: 1400,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000003",
    auctionId: "a0000000000000000003",
    bidAmount: 1400,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 6),
  },
};

export const auction4Mock: IAuctionMock = {
  id: "a0000000000000000004",
  isIllustrative: true,
  image: DISC1,
  imageRelativePath: "/auctions/a0000000000000000004/images/a0000000000000000004_img01.png",
  zipcode: "95610",
  patronEstimateType: "per_event",
  patronEstimateTypeOther: null,
  patronEstimate: 60,
  sponsorshipTitle: "Local Bar seeks sponsor for live events",
  sponsorshipType: "discount",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "bar",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 1, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will prominently display your sponsored drink discount on our menu.",
  desiredSponsorDescription: "We seek local home service providers to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 19),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 500,
  allowOutrightBuy: true,
  outrightBuyPrice: 600,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000004",
    auctionId: "a0000000000000000004",
    bidAmount: 550,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 6),
  },
};

export const auction5Mock: IAuctionMock = {
  id: "a0000000000000000005",
  isIllustrative: true,
  image: TV1,
  imageRelativePath: "/auctions/a0000000000000000005/images/a0000000000000000005_img01.png",
  zipcode: "95640",
  patronEstimateType: "per_week",
  patronEstimateTypeOther: null,
  patronEstimate: 1000,
  sponsorshipTitle: "Local Pizza Restaurant seeks sponsor for TV",
  sponsorshipType: "sign",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "diningroom",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 2, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will prominently display your sign under our main TV that we use to show sports.",
  desiredSponsorDescription: "We seek local home service providers to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 20),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 100,
  allowOutrightBuy: true,
  outrightBuyPrice: 200,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000005",
    auctionId: "a0000000000000000005",
    bidAmount: 50,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 5),
  },
};

export const auction6Mock: IAuctionMock = {
  id: "a0000000000000000006",
  isIllustrative: true,
  image: SINK1,
  imageRelativePath: "/auctions/a0000000000000000006/images/a0000000000000000006_img01.png",
  zipcode: "95640",
  patronEstimateType: "per_month",
  patronEstimateTypeOther: null,
  patronEstimate: 5000,
  sponsorshipTitle: "Local Bar seeks sponsor for bathroom",
  sponsorshipType: "sign",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "bathroom",
  sponsorshipLocationTypeOther: null,
  termOfSponsorshipInMonths: 3, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will prominently display your sign in the line of view of patrons who are washing their hands.",
  desiredSponsorDescription: "We seek local plumbers to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 20),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: true,
  reservePrice: 200,
  allowOutrightBuy: false,
  outrightBuyPrice: null,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: null,
};

export const auction7Mock: IAuctionMock = {
  id: "a0000000000000000007",
  isIllustrative: true,
  image: TV3,
  imageRelativePath: "/auctions/a0000000000000000007/images/a0000000000000000007_img01.png",
  zipcode: "95620",
  patronEstimateType: "per_week",
  patronEstimateTypeOther: null,
  patronEstimate: 700,
  sponsorshipTitle: "Pool hall seeks sponsor for TV",
  sponsorshipType: "sign",
  sponsorshipTypeOther: null,
  sponsorshipLocationType: "other",
  sponsorshipLocationTypeOther: "On the wall behind our pool tables.",
  termOfSponsorshipInMonths: 2, // LATER: Update data model field name and type
  additionalSponsorshipDescription: "We will prominently display your sign under our main TV that we use to show sports.",
  desiredSponsorDescription: "We seek sporting goods stores to bid.",
  otherInformation: "",
  auctionEndDate: new Date(2023, 3, 20),
  showInAuctionListings: true,
  useAnonymousAuction: false,
  requireVerifiedBidders: true,
  enforceReservePrice: false,
  reservePrice: null,
  allowOutrightBuy: true,
  outrightBuyPrice: 400,
  dateOfCreation: new Date(2023, 3, 1),
  hasBeenPublished: true,
  dateOfPublication: new Date(2023, 3, 4),
  largestBid: {
    id: "ab000000000000000007",
    auctionId: "a0000000000000000007",
    bidAmount: 400,
    otherInfo: "",
    dateOfCreation: new Date(2023, 3, 6),
  },
};

export const auctionMocks: Array<IAuctionMock> = [auction1Mock, auction2Mock, auction3Mock, auction4Mock, auction5Mock, auction6Mock, auction7Mock];
