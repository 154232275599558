import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {Card, Image, Stack} from "react-bootstrap";
import * as OPA from "open-personal-archive-base";
import * as C from "../../Constants";
import {auctionMocks, getPatronEstimateTypeText, getBiddingStatusText, getAuctionStatusClassesAndText} from "../../DataMock";

type SearchItemProps = {
  auctionId: string;
};

const SearchItemView: FunctionComponent<SearchItemProps> = (props: SearchItemProps) => {
  const auctionMock = auctionMocks.find((a) => (a.id == props.auctionId));
  OPA.assertNonNullish(auctionMock, "The auctionId must correspond to valid auction.");
  const auctionMockNonNull = OPA.convertNonNullish(auctionMock);

  let illustrativeOverlayJsx = <></>;
  if (auctionMockNonNull.isIllustrative) {
    illustrativeOverlayJsx = (
      <div className="SearchItemImageOverlay">Illustrative</div>
    );
  }
  const biddingStatusText = getBiddingStatusText(auctionMockNonNull);
  const auctionStatus = getAuctionStatusClassesAndText(auctionMockNonNull);

  return (
    <div className="SearchItemContainer">
      <Stack direction="horizontal" gap={3}>
        <div className="SearchItemImageContainer">
          <Image src={auctionMockNonNull.image} alt="Auction Image" rounded />
          {illustrativeOverlayJsx}
        </div>
        <Card style={{width: "auto"}}>
          <Card.Body>
            <Link to={"/auctions/" + auctionMockNonNull.id}>
              <Card.Title className="SearchItemCardTitle">{auctionMockNonNull.sponsorshipTitle}</Card.Title>
            </Link>
            <Card.Text>
              <b>Zipcode of Establishment:</b> {auctionMockNonNull.zipcode}
              {C.spacerText}
              <b>Patron Esitmate:</b> {auctionMockNonNull.patronEstimate + " " + getPatronEstimateTypeText(auctionMockNonNull.patronEstimateType, auctionMockNonNull.patronEstimateTypeOther)}
            </Card.Text>
            <Card.Text>
              <b>Sponsorship Term:</b> {auctionMockNonNull.termOfSponsorshipInMonths} months
            </Card.Text>
            <Card.Text>
              <b>Bidding Status:</b> {biddingStatusText}
            </Card.Text>
          </Card.Body>
          <Card.Footer className={auctionStatus[1]}><b>{auctionStatus[2]}</b></Card.Footer>
        </Card>
      </Stack>
    </div>
  );
};

export default SearchItemView;
