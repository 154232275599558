import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {EmptyProps} from "../../Interfaces";

const FooterView: FunctionComponent<EmptyProps> = (props: EmptyProps) => {
  return (
    <Container className="Footer GridSmallPad" fluid>
      <Row className="GridNoPad">
        <Col className="GridNoPad" xs={4}>
          <Stack className="FooterContainerLeft" direction="horizontal" gap={3}>
            <Link to={"/termsofuse"}><b>Terms of Use</b></Link>
            <Link to={"/privacypolicy"}><b>Privacy Policy</b></Link>
          </Stack>
        </Col>
        <Col className="GridNoPad" xs={4}>
          <div className="FooterContainerMiddle">
            <h4>Copyright © 2023 adcumulate™</h4>
          </div>
        </Col>
        <Col className="GridNoPad" xs={4}>
          <Stack className="FooterContainerRight" direction="horizontal" gap={3}>
            <Link to={"/aboutus"}><b>About Us</b></Link>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterView;
