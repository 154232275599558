import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";

const NewUserView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("NewUserView");
  });

  // const params = useParams();
  return (
    <div className="Content">
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={3}></Col>
          <Col className="GridNoPad" xs={6}>
            <Stack direction="vertical" gap={3}>
              <h2>Welcome New User!</h2>
              <div>
                <h4>Username:</h4>
                <h4>Organization Name:</h4>
              </div>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={3}></Col>
        </Row>
      </Container>
    </div >
  );
};

NewUserView.placeholderText = "[NEW_USER]";
NewUserView.routes = {
  path: "newuser",
  element: <NewUserView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
    {path: ":id", element: <div />},
  ],
};
NewUserView.displayNameGenerator = (args) => "New User";
NewUserView.linkToGenerator = (args) => NewUserView.routes.path!;
NewUserView.urlGenerator = (args) => new URL(NewUserView.linkToGenerator(args), urlBase).href;

export default NewUserView;
