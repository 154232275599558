import React from "react";
import * as OPA from "open-personal-archive-base";
import {PageViews} from "./PageViewDirectory";

export const JsxStartTag = "<<<<";
export const JsxEndTag = ">>>>";

export const isNumber = (text: string) => {
  if (OPA.isNullishOrWhitespace(text)) {
    return false;
  } else {
    return (("0" <= text[0]) && ((text[0]) <= "9"));
  }
};

export const isNumberedHeader = (text: string) => {
  if (OPA.isNullishOrWhitespace(text)) {
    return false;
  } else if (isNumber(text[0]) && (text[1] == ".")) {
    return true;
  } else if (isNumber(text[0]) && isNumber(text[1]) && (text[2] == ".")) {
    return true;
  } else if (isNumber(text[0]) && isNumber(text[1]) && isNumber(text[2]) && (text[3] == ".")) {
    return true;
  } else {
    return false;
  }
};

export const insertUrlsIntoText = (text: string, addTagsForJsx: boolean, args?: Record<string, string | undefined> | undefined) => {
  const argsNonNull = (!OPA.isNullish(args)) ? OPA.convertNonNullish(args) : {};
  let textWithUrls = text;
  for (let i = 0; i < PageViews.length; i++) {
    const pageView = PageViews[i];
    const pageUrl = pageView.urlGenerator(argsNonNull);
    const pageUrlWithTags = (addTagsForJsx) ? (JsxStartTag + pageUrl + JsxEndTag) : pageUrl;
    textWithUrls = textWithUrls.replace(pageView.placeholderText, pageUrlWithTags);
  }
  return textWithUrls;
};

export const formatTextPartAsJsx = (textPart: string, textPartIndex: number, args?: Record<string, string | undefined> | undefined) => {
  const textPartWithUrls = insertUrlsIntoText(textPart, true);
  let textPartToCheck = textPartWithUrls;
  let textPartJsx = <></>;

  for (let i = textPartToCheck.length - 1; i >= 0; i--) {
    const startChars = textPartToCheck.substring(i, i + JsxStartTag.length);
    if (startChars != JsxStartTag) {
      continue;
    }

    const endTagIndex = textPartToCheck.indexOf(JsxEndTag, i + JsxStartTag.length);
    const urlPart = textPartToCheck.substring(i + JsxStartTag.length, endTagIndex);
    const endPart = textPartToCheck.substring(endTagIndex + JsxEndTag.length, textPartToCheck.length);

    textPartJsx = <><a href={urlPart}>{urlPart}</a>{endPart}{textPartJsx}</>;
    textPartToCheck = textPartToCheck.substring(0, i);
  }
  textPartJsx = <>{textPartToCheck}{textPartJsx}</>;

  if (textPartIndex == 0) {
    textPartJsx = <p style={{fontSize: "1.5rem"}}><b>{textPartJsx}</b></p>;
  } else if (textPartIndex == 1) {
    textPartJsx = <p style={{fontSize: "1.1rem"}}><b>{textPartJsx}</b></p>;
  } else if (isNumberedHeader(textPartWithUrls)) {
    textPartJsx = <p><b>{textPartJsx}</b></p>;
  } else {
    textPartJsx = <p>{textPartJsx}</p>;
  }
  return textPartJsx;
};
