import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import {initializeApp} from "firebase/app";
import {getFunctions} from "firebase/functions";
import * as OPA from "open-personal-archive-base";
import "./index.css";
import {logInitialization} from "./Firebase";
import App from "./App";
// LATER: import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
// const firebaseFuncs = getFunctions(firebaseApp);
getFunctions(firebaseApp);
logInitialization({projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID, appId: process.env.REACT_APP_FIREBASE_APP_ID});

const rootDiv = document.getElementById("root");
OPA.assertNonNullish(rootDiv, "The root div must not be null.");
const rootDivNonNull = OPA.convertNonNullish(rootDiv);

const root = ReactDOM.createRoot(rootDivNonNull);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// LATER: reportWebVitals();
