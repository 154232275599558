import React, {useEffect} from "react";
import {Link, Navigate} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";

const AboutUsView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("AboutUsView");
  });

  return (
    <div className="Content">
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={2}></Col>
          <Col className="GridNoPad" xs={8}>
            <Stack direction="vertical" gap={3}>
              <h2>About Us</h2>
              <div>
                <p>
                  Have you ever noticed the vast amount of advertising signage that is
                  telecasted during live events?
                </p>
                <p>
                  The dollars earned from such advertising can substantially offset costs
                  that might otherwise require more expensive ticket prices.
                </p>
                <p>
                  We created adcumulate™ so that local venues could easily use the same strategy
                  to offset their costs, while driving as much value as possible through our
                  competitive-bid auction system and wide field of potential sponsors, all while
                  maintaining their anonymity up until the point that a paying sponsor is found
                  for their business' advertising opportunity.
                </p>
                <p>
                  If you want to use local advertising to provide a better value to your own
                  patrons or drive more patrons to your business, please
                  {" "}<Link to={"/requestaccess"}><b>click here to Request Access</b></Link>.
                </p>
                <p>
                  You can also email us at <b>ryane</b>[at]<b>alumni</b>[dot]<b>stanford</b>[dot]<b>edu</b> with any questions...
                </p>
              </div>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

AboutUsView.placeholderText = "[ABOUT_US]";
AboutUsView.routes = {
  path: "aboutus",
  element: <AboutUsView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
  ],
};
AboutUsView.displayNameGenerator = (args) => "About Us";
AboutUsView.linkToGenerator = (args) => AboutUsView.routes.path!;
AboutUsView.urlGenerator = (args) => new URL(AboutUsView.linkToGenerator(args), urlBase).href;

export default AboutUsView;
