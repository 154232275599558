import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import {Col, Container, Row, Stack} from "react-bootstrap";
import {logPageView} from "../Firebase";
import {EmptyProps, RoutableFunctionComponent, urlBase} from "../Interfaces";
import * as TextFormatter from "../TextFormatter";
import privacyPolicy from "../legal/adcumulate_PrivacyPolicy_2023_05_11";

const PrivacyPolicyView: RoutableFunctionComponent<EmptyProps> = (props: EmptyProps) => {
  useEffect(() => {
    logPageView("PrivacyPolicyView");
  });

  // NOTE: Rather than wait to open a text file, just import a constant containing the multi-line text
  // const [privacyPolicy, setPrivacyPolicy] = useState("");
  // useEffect(() => {
  //   fetch(PrivacyPolicy)
  //     .then(async (file) => {
  //       const fileText = await file.text();
  //       setPrivacyPolicy(fileText);
  //     });
  // });

  const fileTextParts = privacyPolicy.split("\n");
  let fileJsx = <></>;

  for (let i = 0; i < fileTextParts.length; i++) {
    const fileTextPart = fileTextParts[i];
    const fileTextPartJsx = TextFormatter.formatTextPartAsJsx(fileTextPart, i);

    fileJsx = <>{fileJsx}{fileTextPartJsx}</>;
  }

  return (
    <div className="Content">
      <Container className="GridSmallPad" fluid>
        <Row className="GridNoPad">
          <Col className="GridNoPad" xs={2}></Col>
          <Col className="GridNoPad" xs={8}>
            <Stack direction="vertical" gap={3}>
              <h2>Privacy Policy</h2>
              <div style={{textAlign: "left"}}>
                {fileJsx}
              </div>
            </Stack>
          </Col>
          <Col className="GridNoPad" xs={2}></Col>
        </Row>
      </Container>
    </div>
  );
};

PrivacyPolicyView.placeholderText = "[PRIVACY_POLICY]";
PrivacyPolicyView.routes = {
  path: "privacypolicy",
  element: <PrivacyPolicyView />,
  children: [
    {path: "*", element: <Navigate to="/404" />},
  ],
};
PrivacyPolicyView.displayNameGenerator = (args) => "Privacy Policy";
PrivacyPolicyView.linkToGenerator = (args) => PrivacyPolicyView.routes.path!;
PrivacyPolicyView.urlGenerator = (args) => new URL(PrivacyPolicyView.linkToGenerator(args), urlBase).href;

export default PrivacyPolicyView;
